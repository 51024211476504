<template>
  <div>
    <div class="row page-title">
      <label v-cloak>{{ pageTitle }}</label>
    </div>
    <div class="page-details row">
      <div
        class="text-center text-danger col-lg-12"
        v-if="IsDataLoaded && !IsApproveOrRejectEnabled"
      >
        The Request is already approved or rejected.
      </div>
      <product-overview
        class="col-lg-9"
        :Request="Request"
        :IsComplete="Complete"
        :ID="id"
      ></product-overview>
      <div class="col-lg-3 approval-side-section">
        <div class="row">
          <p class="approve-reject-label">Complete or Reject Product Request</p>
          <p class="approve-reject-content">
            Please review and ensure all codes are correct before completing
            this product request.
          </p>
          <div class="text-right" style="width: 100%">
            <button
              v-if="IsApproveOrRejectEnabled && !$v.Request.Products.$invalid"
              class="btn custom-button btn-primary"
              @click="Approve()"
            >
              Complete
            </button>
            <button
              v-else
              class="btn custom-button btn-primary"
              disabled="disabled"
              title="The request is already completed, rejected or product code is not valid."
            >
              Complete
            </button>
          </div>
        </div>
        <div class="row reject-section">
          <label class="rejection-label">Rejection Comments</label>
          <b-textarea
            class="rejection-comments"
            placeholder="Enter Rejection Comments"
            v-model="Comments"
            v-bind:class="{
                  errorborder: !$v.Comments.required && $v.Comments.$dirty,
                  noterrorborder: $v.Comments.required,
                }"
            rows="5"
             @blur="SetDirty()"
            @focus="ResetDirty()"
          >
          </b-textarea>
          <div class="error-message-format required-field" v-if="!$v.Comments.required && $v.Comments.$dirty">
            Required field
          </div>
          <div class="text-right" style="width: 100%; padding-top: 10px">
            <button
              v-if="IsApproveOrRejectEnabled"
              class="btn custom-button btn-outline-primary"
              @click="Reject()"
            >
              Reject
            </button>
            <button
              v-else
              class="btn custom-button btn-outline-primary"
              disabled="disabled"
              title="The request is already approved or rejected."
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-form-group
      class="text-right footer-buttons"
      v-if="!Error && !HideFooterButtons"
    >
      <button class="btn custom-button btn-link" @click="Cancel()">
        Cancel
      </button>
      <button
        class="btn custom-button btn-outline-primary"
        @click="ShowReject()"
        v-if="IsApproveOrRejectEnabled"
      >
        Reject
      </button>
      <button
        v-else
        class="btn custom-button btn-outline-primary"
        title="The request is already approved or rejected."
        disabled="disabled"
      >
        Reject
      </button>

      <button
        v-if="IsApproveOrRejectEnabled"
        class="btn custom-button btn-primary"
        @click="Approve()"
      >
        Complete
      </button>
      <button
        v-else
        disabled="disabled"
        class="btn custom-button btn-primary"
        title="The request is already approved or rejected."
      >
        Complete
      </button>
    </b-form-group>
    <b-modal
      v-model="ShowRejectModal"
      :no-close-on-backdrop="true"
      scrollable
      title="Add Product"
    >
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title">{{ ModalTitle }}</h5>
        <button class="close" @click="CancelReject()"></button>
      </template>
      <div
        class="
          d-block
          row
          col-lg-6 col-md-8
          offset-lg-2 offset-lg-3
          primary-content
        "
      >
        <b-row>
          <b-col>
            <label>Rejection Comments</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea v-model="Comments" rows="5"> </b-textarea>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="text-right">
          <button class="btn btn-link text-uppercase" @click="CancelAdd()">
            Cancel
          </button>
          <button
            :disabled="$v.$invalid"
            class="text-uppercase btn custom-button btn-primary"
            @click="Reject()"
          >
            Reject
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  requiredIf,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import store from "@/store";
import authenticate from "../../mixins/productworkflowvauthorization";
//import * as status from "../../utilities/ProductWorkflowStates";
export default {
  watch: {},
  name: "ApproveLevel2",
  mixins: [authenticate],
  validations() {
    if (this.Request && this.Request.Products != null) {
      return {
        Comments: {
          required: requiredIf(function () {
            return this.RejectComments == true;
          }),
        },
        Request: {
          Products: {
            $each: {
              ProductCode: {
                required,
                maxLength: maxLength(3),
                minLength: minLength(2),
                unique(value) {
                  return (
                    !this.Request.Products ||
                    this.Request.Products.filter(
                      (val) => val.ProductCode == value
                    ).length < 2
                  );
                },
              },
            },
          },
        },
      };
    } else {
      return {
        Comments: {
          required: requiredIf(function () {
            return this.RejectComments == true;
          }),
        },
      };
    }
  },
  data() {
    return {
      Request: { Products: [] },
      Error: false,
      ShowRejectModal: false,
      Comments: "",
      Complete: true,
      HideFooterButtons: true,
      RejectComments:false,
    };
  },
  props: ["id"],
  computed: {
    RequestedCode() {
      return this.Request ? this.Request.ProductCode : "";
    },
    ModalTitle() {
      return `Reject Request ${this.ClientName}`;
    },
    canApproveCurrentWorkflowRequest() {
      return this.$store.getters.canApproveProductRequestLevel2;
    },
    IsApproveOrRejectEnabled() {
      return true; // this.Request&&this.Request.TaskStatus==status.ApproveLevel2;
    },
    IsDataLoaded() {
      return this.Request && this.Request.ProductCode ? true : false;
    },
  },
  methods: {
       SetDirty(){
  this.$v.Comments.$touch();
    },
    ResetDirty(){
  this.$v.Comments.$reset();
    },
    GetData() {
      return {
        ID: parseInt(this.id),
        Comments: this.Comments,
        ActiveTaskID: this.Request.ActiveTaskID | 0,
        ProjectID: this.Request.OmniflowProjectID | 0,
        ProcessID: this.Request.OmniflowProcessID | 0,
        Products: this.Request.Products,
      };
    },
    ShowReject() {
      this.ShowRejectModal = true;
    },
    CancelReject() {
      this.ShowRejectModal = false;
    },
    Reject() {
      this.ShowRejectModal = false;
      this.RejectComments=true;
          this.$v.Comments.$touch();
      if(!this.$v.$invalid){
      this.$store
        .dispatch("product/RejectRequestLevel2", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `Request ${this.pageTitle} has been rejected`
          );
          this.$router.push("/ClientProduct");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while rejecting request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while rejecting request", ex);
          }
        });
      }
    },
    Approve() {
      this.ShowRejectModal = false;
      this.$store
        .dispatch("product/ApproveRequestLevel2", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.pageTitle} has been completed`
          );
          this.$router.push("/ClientProduct");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while completing request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while approving request", ex);
          }
        });
    },
    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.RequestedCode}. The error is ${ex}`
      );
    },
    Cancel() {
      this.$router.push("/ClientProduct");
    },
  },
};
</script>
<style scoped>
.reject-section {
  padding-bottom: 10px;
}
@media screen and (min-width: 1200px) {
  .page-details {
    padding: 1rem 3rem 0px !important;
  }
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}
.review-component > div:first-child {
  box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
  border: solid 1px rgba(239, 242, 244, 0.2) !important;
  border-right: none;
  border-radius: 8px 0px 0px 8px;
  background-color: #ffffff;
}
</style>